import Vue				from 'vue';
import VueRouter	from 'vue-router';
import store			from '@/store/index.js';

Vue.use(VueRouter)

const routes = [
	{ path: '/',								name: 'home',						component: () => import('@/views/Home.vue'),						meta: {requireAuth: false} },
	{ path: '/login',						name: 'login',					component: () => import('@/views/Login.vue'),						meta: {requireAuth: false} },
	{ path: '/recovery',				name: 'recovery',				component: () => import('@/views/Recovery.vue'),				meta: {requireAuth: false} },
	{ path: '/register',				name: 'register',				component: () => import('@/views/Register.vue'),				meta: {requireAuth: false} },
	// { path: '/contacto',				name: 'contacto',				component: () => import('@/views/Contacto.vue'),				meta: {requireAuth: false} }, // not used yet
	{ path: '/flyers',					name: 'flyers', 				component: () => import('@/views/Flyers.vue'),					meta: {requireAuth: false} }, 
	{ path: '/novedades',				name: 'novedades', 			component: () => import('@/views/Novedades.vue'),				meta: {requireAuth: false} },
	{ path: '/aplicaciones',		name: 'aplicaciones',		component: () => import('@/views/Aplicaciones.vue'),		meta: {requireAuth: true} },
	{ path: '/aviso-pago',			name: 'avisoPago', 			component: () => import('@/views/AvisoPago.vue'),				meta: {requireAuth: true} },
	{ path: '/catalogos',				name: 'catalogos',			component: () => import('@/views/Catalogos.vue'),				meta: {requireAuth: true} },	
	{ path: '/comprobantes',		name: 'comprobantes',		component: () => import('@/views/Comprobantes.vue'),		meta: {requireAuth: true} },
	{ path: '/cfg-cuenta',			name: 'cfgCuenta', 			component: () => import('@/views/CfgCuenta.vue'),				meta: {requireAuth: true} },
	{ path: '/cfg-descuentos',	name: 'cfgDescuentos',	component: () => import('@/views/CfgDescuentos.vue'),		meta: {requireAuth: true} },
	{ path: '/cfg-utilidades',	name: 'cfgUtilidades',	component: () => import('@/views/CfgUtilidades.vue'),		meta: {requireAuth: true} },
	{ path: '/descargas',				name: 'descargas',			component: () => import('@/views/Descargas.vue'),				meta: {requireAuth: false} },
	{ path: '/payments',				name: 'paymetns',				component: () => import('@/views/Payments.vue'),				meta: {requireAuth: true} },
	{ path: '/carrito',					name: 'carrito',				component: () => import('@/views/OrderInProgress.vue'),	meta: {requireAuth: true} },
	{ path: '/pedidos',					name: 'pedidos',				component: () => import('@/views/OrdersHistory.vue'),		meta: {requireAuth: true} },
	{ path: '/order-summary',		name: 'orderSummary',		component: () => import('@/views/OrderSummary.vue'),		meta: {requireAuth: false} },
	// { path: '/checkout-login',	name: 'checkoutLogin',	component: () => import('@/views/checkout/CheckoutLogin.vue'),		meta: {requireAuth: false} },
	{ path: '/checkout',				name: 'checkout',				component: () => import('@/views/Checkout.vue'),				meta: {requireAuth: false} },
	// { path: '/checkout-result',	name: 'checkoutResult', component: () => import('@/views/checkout/CheckoutResult.vue'),	meta: {requireAuth: false} },
	{ path: '/productos',				name: 'productos',			component: () => import(`@/views/Productos.vue`),				meta: {requireAuth: false} },
	{ path: '/resumen-cuenta',	name: 'resumenCuenta',	component: () => import('@/views/ResumenCuenta.vue'),		meta: {requireAuth: true} }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	const rutaProtegida = to.matched.some(record => record.meta.requireAuth)
	if (rutaProtegida && !store.getters.isAuthenticated) {
		next({name: 'login'});
	} else {
		next();
	}
})

export default router;
